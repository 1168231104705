import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import BaoGiaPage from './baogia';
import OrderPage from './order';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PayPage from './pay';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ProductPage from './product';
import MenuPage from './menu';
import CampaignsPage from './campaign';

const drawerWidth = 200;


function HomePage(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [valueMenu, setValueMenu] = React.useState("zalo");
    const [valueMenuSub, setValueMenuSub] = React.useState("baogia");
    const [valueAdd, setValueAdd] = React.useState(0);
    const [content, setContent] = useState('');
    const [valueAddName, setValueAddName] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [openSub, setOpenSub] = React.useState(true);
    const [openSubOrder, setOpenSubOrder] = React.useState(false);
    const [openSubProduct, setOpenSubProduct] = React.useState(false);

    // const fetchProducts = async () => {
    //     try {
    //         const response = await fetch("https://zns.hcrm.online/API/product/", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "API-Key": "810XuINh85DFYTLobBxMnTsrtx0QUTQHdAqVMyKP",
    //             },
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         const result = await response.json();
    //         if (result.message === "success") {
    //             setProducts(result.data);
    //         } else {
    //             throw new Error("Failed to fetch products");
    //         }
    //     } catch (err) {
    //         setError(err.message);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    useEffect(() => {
        // Fetch dữ liệu từ API
        fetch('https://sieuthisatthep.net/API/product/get.php')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const handleClickSub = () => {
        setOpenSub(!openSub);
        setValueMenu('zalo');
        setOpenSubOrder(false);
        setOpenSubProduct(false);
    };
    const handleClickSubOrder = () => {
        setOpenSubOrder(!openSubOrder);
        setValueMenu('order');
        setOpenSub(false);
        setOpenSubProduct(false);
    };

    const handleClickSubProduct =()=>{
        setOpenSubProduct(!openSubProduct);        
        setValueMenu('product');
        setOpenSub(false);
        setOpenSubOrder(false);
    }
    const drawer = (
        <div>
            {/* <Toolbar /> */}
            <div style={{
                display: 'flex',
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center'
            }}>
                <h1>ADMIN</h1>
            </div>

            <Divider />
            <List>
                {/* <ListItem key={'dashboard'} disablePadding
                    sx={{
                        backgroundColor: valueMenu === 'dashboard' ? 'teal' : '#fff',
                        color: valueMenu === 'dashboard' ? '#fff' : '#000'
                    }}>
                    <ListItemButton onClick={() => {
                        setValueMenu('dashboard');
                        setOpenSub(false);
                    }}>
                        <ListItemIcon>
                            <GridViewOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Tổng quan"} />
                    </ListItemButton>

                </ListItem> */}
                <ListItem key={'zalo'} disablePadding
                    sx={{
                        backgroundColor: valueMenu === 'zalo' ? 'teal' : '#fff',
                        color: valueMenu === 'zalo' ? '#fff' : '#000'
                    }}>
                    <ListItemButton onClick={handleClickSub}>
                        <ListItemIcon>
                            <ChatOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Gửi Zalo"} />
                        {openSub ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                </ListItem>
                <Collapse in={openSub} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* const [valueMenuSub, setValueMenuSub] = React.useState(""); */}
                        <ListItem key={'thanhtoan'} disablePadding sx={{ color: valueMenuSub === 'thanhtoan' ? 'blue' : '#000' }}>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => setValueMenuSub('thanhtoan')}>
                                {/* <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon> */}
                                <ListItemText style={{ fontSize: '14px' }} primary={"Xác nhận thanh toán"} />
                            </ListItemButton>

                        </ListItem>
                        <ListItem key={'baogia'} disablePadding sx={{ color: valueMenuSub === 'baogia' ? 'blue' : '#000' }}>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => setValueMenuSub('baogia')}>
                                {/* <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon> */}
                                <ListItemText style={{ fontSize: '14px' }} primary={"Báo giá"} />
                            </ListItemButton>

                        </ListItem>
                        <ListItem key={'order'} disablePadding sx={{ color: valueMenuSub === 'order' ? 'blue' : '#000' }}>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => setValueMenuSub('order')}>
                                {/* <ListItemIcon>
                                    <CardTravelIcon />
                                </ListItemIcon> */}
                                <ListItemText primary={"Phiếu xuất bán hàng"} />
                            </ListItemButton>

                        </ListItem>
                        <ListItem key={'camp'} disablePadding sx={{ color: valueMenuSub === 'camp' ? 'blue' : '#000' }}>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => setValueMenuSub('camp')}>
                                {/* <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon> */}
                                <ListItemText style={{ fontSize: '14px' }} primary={"Chiến dịch"} />
                            </ListItemButton>

                        </ListItem>
                    </List>
                </Collapse>


                
               

            </List>

        </div>
    );
    const container = window !== undefined ? () => window().document.body : undefined;

    function sendData() {
        const slug = convertToSlug(valueAddName);
        fetch("https://sieuthisatthep.net/API/product/add.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                post_content: content,
                post_title: valueAddName,
                post_name: slug
            })
        })
            .then(response => response.json())
            .then(data => {
                setOpen(true);
                console.log("Phản hồi từ PHP:", data);
            })
            .catch(error => {
                console.error("Có lỗi xảy ra:", error);
            });
    }

    function convertToSlug(str) {
        // Bước 1: Loại bỏ dấu tiếng Việt
        // Bước 1: Loại bỏ dấu tiếng Việt
        const from = "ÁÀẠÃẢÂẤẦẬẪẨĂẮẰẶẴẲÉÈẸẼẺÊẾỀỆỄỂÓÒỌÕỎÔỐỒỘỖỔƠỚỜỢỠỞÚÙỤŨỦƯỨỪỰỮỬÍÌỊĨỈÝỲỴỸỶĐáàạãảâấầậẫẩăắằặẵẳéèẹẽẻêếềệễểóòọõỏôốồộỗổơớờợỡởúùụũủưứừựữửíìịĩỉýỳỵỹỷđ";
        const to = "AAAAAAAAAAAAAAAAAEEEEEEEEEEEEOOOOOOOOOOOOOOOOOUUUUUUUUUUUIIIIIYYYYYDaaaaaaaaaaaaaaaaaeeeeeeeeeeeeooooooooooooooooouuuuuuuuuuuiiiiiyyyyyd";

        let slug = str.split('').map((char, i) => {
            const index = from.indexOf(char);
            return index !== -1 ? to[index] : char;
        }).join('');

        // Bước 2: Thay thế khoảng trắng và ký tự đặc biệt thành dấu gạch ngang
        slug = slug
            .replace(/[^a-zA-Z0-9\s]/g, '')   // Loại bỏ ký tự đặc biệt trừ chữ cái và số
            .replace(/\s+/g, '-')              // Thay thế khoảng trắng bằng gạch ngang
            .toLowerCase();                    // Chuyển thành chữ thường

        return slug;
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Định vị top-right
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Thêm sản phẩm thành công
                </Alert>
            </Snackbar>
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>

                    </Box>

                    <Chip avatar={<Avatar>A</Avatar>} label="Admin" />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 1,
                    marginTop: '45px',
                    backgroundColor: '#F2F2F2',
                    width: { sm: `calc(100% - ${drawerWidth}px)` }
                }}
            >
                {
                    valueMenuSub === "product" &&
                    <div>
                        {
                            valueAdd === 0 &&
                            <div>
                                <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
                                    <div style={{ flexGrow: 1 }}>Danh sách sản phẩm</div>
                                    <Button size='small' variant="contained" onClick={() => setValueAdd(1)}>Thêm sản phẩm </Button>
                                </div>
                                <Grid container spacing={2}>
                                    {
                                        data.map((item, index) => (
                                            <Grid item size={{ xs: 6, md: 3 }} key={index}>
                                                <Card sx={{
                                                    minWidth: 180, height: '280px',
                                                    cursor: 'pointer',
                                                    ":hover": {
                                                        border: "1px solid blue",
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                                                    }
                                                }}>
                                                    <CardContent>
                                                        <div>
                                                            <img src={item.path_img === '' ? "https://sieuthisatthep.net/wp-content/uploads/woocommerce-placeholder-300x300.png" : item.path_img} width={"100%"} height={'200px'} />
                                                            <div>{item.title}</div>
                                                        </div>
                                                    </CardContent>

                                                </Card>


                                            </Grid>
                                        ))
                                    }


                                </Grid>
                            </div>
                        }
                        {
                            valueAdd === 1 &&
                            <div>
                                <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
                                    <div style={{ flexGrow: 1 }}>Thêm sản phẩm</div>
                                    <Stack spacing={2} direction="row">
                                        <Button variant="text" onClick={() => setValueAdd(0)}>Hủy</Button>
                                        <Button variant="contained" onClick={() => sendData()}>Lưu</Button>

                                    </Stack>
                                </div>
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item size={{ xs: 12, md: 6 }} key={"name"}>
                                            <div>
                                                <div>Tên sản phẩm</div>
                                                <TextField value={valueAddName} onChange={(e) => setValueAddName(e.target.value)} size='small' fullWidth id="outlined-basic" variant="outlined" />
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                <div>Mô tả sản phẩm</div>
                                                <ReactQuill
                                                    value={content}
                                                    style={{ height: '300px' }}
                                                    onChange={setContent}
                                                    placeholder="Nhập văn bản tại đây..."
                                                    modules={{
                                                        toolbar: [
                                                            [{ 'header': [1, 2, false] }],
                                                            ['bold', 'italic', 'underline', 'strike'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            ['link', 'image'],
                                                        ],
                                                    }}
                                                />
                                            </div>

                                        </Grid>
                                    </Grid>

                                </div>

                            </div>
                        }

                    </div>
                }
                {
                    valueMenuSub === 'baogia' &&
                    <BaoGiaPage />


                }
                {
                    valueMenuSub === 'order' &&
                    <OrderPage />
                }
                {
                    valueMenuSub === 'thanhtoan' &&
                    <PayPage />
                }
                {
                    valueMenuSub ==='list_product' && 
                    <ProductPage/>
                }
                {
                    valueMenuSub ==='menu_order' && <MenuPage/>
                }
                {
                    valueMenuSub ==='camp' && <CampaignsPage/>
                }
            </Box>
        </Box>
    );
}
HomePage.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window: PropTypes.func,
};
export default HomePage;