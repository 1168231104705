import React, { useEffect, useState } from "react";
import '../App.css'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { grey, purple } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { alpha } from '@mui/material/styles';
import { Autocomplete, CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';



const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 6px',
    border: '1px solid',
    lineHeight: 1.1,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: purple[700],
    },
}));
const columns = [
    { id: 'STT', label: 'STT', with: 20 },
    { id: 'code', label: 'Số ĐH', with: 30 },
    // { id: 'ngay_ct', label: 'Ngày ĐH', with: 80 },
    {
        id: 'ma_kh',
        label: 'Mã KH',
        with: 65,
        // align: 'center',

    },
    {
        id: 'ten_kh_vat',
        label: 'Tên khách hàng',
        minWidth: 170,
        // align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'phone',
        label: 'Điện thoại',
        minWidth: 70,
        // align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'money',
        label: 'Tổng tiền',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'count',
        label: 'Số lần',
        with: 40,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'action',
        label: 'Hành động',
        minWidth: 70,
        align: 'center',

    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const useStyles = makeStyles({
    textField: {
        '& .MuiInputBase-root': {
            height: '35px',  // Adjust this value to your desired height
        },
    },
});
const useStyles1 = makeStyles({
    textField: {
        '& .MuiInputBase-root': {
            height: '42px',  // Adjust this value to your desired height
            with: '100%'
        },
    },
});

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.35),
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: grey,
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100ch',
        },
    },
}));
const BaoGiaPage = () => {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    const [value, setValue] = React.useState(dayjs());
    const [valueNgay, setValueNgay] = React.useState(dayjs().format('YYYY-MM-DD'));
    const [open, setOpen] = React.useState(false);
    const [openMobile, setOpenMobile] = React.useState(false);
    const [openChiendich, setOpenChiendich] = React.useState(false);
    const classes = useStyles();
    const classes1 = useStyles1();
    const [valueCode, setValueCode] = React.useState('');
    const [valueName, setValueName] = React.useState('');
    const [valuePhone, setValuePhone] = React.useState('');
    const [valueNote, setValueNote] = React.useState('');
    const [valueOrderCode, setValueOrderCode] = React.useState(null);
    const [valuePrice, setValuePrice] = React.useState('');
    const [valueStatus, setValueStatus] = React.useState('Báo giá');
    const [valueCheck, setValueCheck] = useState("");

    const [startDate, setStartDate] = useState(null); // State cho "Từ ngày"
    const [endDate, setEndDate] = useState(null); // State cho "Đến ngày"
    const [orderDate, setOrderDate] = useState(null); // State cho "Đến ngày"
    const [dataCamp, setDataCamp] = useState([]);
    const [valueNameCamp, setValueNameCamp] = useState("");
    const [valueMoneyCamp, setValueMoneyCamp] = useState("");
    const [valueGio, setValueGio] = React.useState(dayjs());
    // const container = window !== undefined ? () => window().document.body : undefined;
    // console.log(container);
    useEffect(() => {
        // Fetch dữ liệu từ API         
        if (valueNgay !== '') {
            fetchData();

        }
        // fetchDataCam();
    }, [valueNgay]);
    const fetchData = async () => {
        setData([]);
        try {
            const response = await fetch('https://zns.hcrm.online/API/BaoGia/get.php', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ngay_ct: valueNgay,
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setData(data);
            const totalAmount = data.reduce((acc, row) => acc + parseFloat(row.t_tt), 0);
            setValueTotal(totalAmount);
        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const fetchDataCam = async () => {

        try {
            const response = await fetch('https://zns.hcrm.online/API/Campaigns/get.php', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ngay_ct: valueNgay,
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            if (data) {
                setValueNameCamp(data[0].Name);
                setValueMoneyCamp(parseInt(data[0].fromMoney));

                setValueGio(dayjs(data[0].time_Send))
            }
            setDataCamp(data);

        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleDateChange = (newValue) => {
        // Format the value as 'YYYY-MM-DD'
        const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : null;
        setValue(newValue);
        setValueNgay(formattedDate);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [valueRec, setValueRec] = useState("");
    const [valuect, setValuect] = useState("");
    const handleClickOpen = (item) => {
        setOpen(true);

        // Gọi hàm để lấy ngày dạng 'YYYYMMDD'
        const getFormattedDate = () => dayjs().format('YYYYMMDD');

        const formattedDate = getFormattedDate(); // Gọi hàm để lấy giá trị

        console.log(formattedDate); // In ra giá trị của ngày

        // Kết hợp ngày với mã đơn hàng
        setValueOrderCode(formattedDate + item.so_ct);
        const formattedNumber = Number(parseFloat(item.t_tt).toFixed(0));
        const formattedValue = formattedNumber ? formatCurrency(formattedNumber) : '';
        setValuePrice(formattedValue);
        setValueName(item.ten_kh_vat);
        setValueRec(item.stt_rec);
        setValuePhone(item.SDT_nguoi_mua);
        setValuect(item.so_ct);
    };

    const handleClickOpenMobile = (item) => {
        setOpenMobile(true);

        // Gọi hàm để lấy ngày dạng 'YYYYMMDD'
        const getFormattedDate = () => dayjs().format('YYYYMMDD');

        const formattedDate = getFormattedDate(); // Gọi hàm để lấy giá trị

        console.log(formattedDate); // In ra giá trị của ngày

        // Kết hợp ngày với mã đơn hàng
        setValueOrderCode(formattedDate + item.so_ct);
        const formattedNumber = Number(parseFloat(item.t_tt).toFixed(0));
        const formattedValue = formattedNumber ? formatCurrency(formattedNumber) : '';
        setValuePrice(formattedValue);
        setValueName(item.ten_kh_vat);
        setValueRec(item.stt_rec);
        setValuePhone(item.SDT_nguoi_mua);
        setValuect(item.so_ct);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handlePriceChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Chỉ giữ lại số
        const formattedValue = inputValue ? formatCurrency(inputValue) : ''; // Định dạng lại thành tiền tệ
        setValuePrice(formattedValue); // Cập nhật giá trị hiển thị
    };

    const handlePriceChangeCamp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Chỉ giữ lại số
        const formattedValue = inputValue ? formatCurrency(inputValue) : ''; // Định dạng lại thành tiền tệ
        setValueMoneyCamp(formattedValue); // Cập nhật giá trị hiển thị
    };
    const handleChangeStatus = (event) => {
        setValueStatus(event.target.value);
    };
    const formatCurrency = (value) => {
        if (!value) return '';
        return new Intl.NumberFormat('en-US').format(value);
    };
    const removeCommas = (numberString) => {
        return numberString.replace(/,/g, "");
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Kiểm tra nếu số điện thoại bắt đầu bằng "0"
        if (phoneNumber.startsWith("0")) {
            // Thay thế ký tự đầu "0" bằng "84"
            return phoneNumber.replace(/^0/, "84");
        }
        return phoneNumber; // Trả về số không thay đổi nếu không bắt đầu bằng "0"
    };
    const handleCloseMobile = () => {
        setOpenMobile(false);
    };
    const [valueNgaySec, setValueNgaySec] = useState("");
    const sendPhone = async () => {

        // if (valueName.length > 30) {
        //     setValueCheck("Tên khách hàng quá dài");
        //     return;
        // }
        if (valuePhone === '') {
            setValueCheck("Vui lòng nhập số điện thoại");
            return;
        }
        if (valuePhone.length !== 10) {
            setValueCheck("Số điện thoại không đúng");
            return;
        }
        // const url = 'https://hoangthongtelecom.com/API/SendZalo/v1/Send_Zns.php';
        const url = 'https://hoangthongtelecom.com/API/ZaloZns/index.php';
        const formattedPhone = formatPhoneNumber(valuePhone);
        const numberWithoutCommas = removeCommas(valuePrice);
        const ngayOrder = dayjs(value).format("DD/MM/YYYY");
        setValueNgaySec(ngayOrder);
        const requestData = {
            phone: formattedPhone,
            code: valueOrderCode,
            total: numberWithoutCommas,
            status: valueStatus,
            ngay: ngayOrder,
            name: valueName

        };
        //setOpen(false);
        //setOpenMobile(false);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //  'API-Key':'846071896322'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const dataAPI = await response.json();
            const value = dataAPI.error;
            if (value === 0) {
                updateStatus();
                setValueCode('');
                setValueName('');
                setValuePhone('');
                setValueOrderCode(null);
                setValuePrice('');
                setValueThongBao("Gửi thành công");
            } else {
                setValueThongBao("Gửi không thành công");
            }
            setOpenThongBao(true);
            console.log(value);

            // setOpenMessage(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const updateStatus = async () => {
        const ngayOrder = dayjs(value).format("YYYY-MM-DD");
        try {
            const response = await fetch('https://zns.hcrm.online/API/BaoGia/update.php', {  // Thêm 'await'
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    so_ct: valuect,
                    stt_rec: valueRec,
                    status: "Đã gửi",
                    phone: valuePhone,
                    ngay: ngayOrder

                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.text();  // Thêm 'await' để resolve JSON
            console.log("Update successful:", data);
            fetchData();
        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const [valueThongBao, setValueThongBao] = React.useState(false);
    const [openThongBao, setOpenThongBao] = React.useState(false);
    const handleCloseThongBao = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenThongBao(false);
    };
    const [valueTotal, setValueTotal] = React.useState(0);
    const handleCloseChienDich = () => {
        setOpenChiendich(false);
    }

    const [valueDateSend, setValueDateSend] = React.useState("1");
    const sendCamp = async () => {


        const formattedDateFrom = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedDateTO = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
        const formattedDateOrder = orderDate ? dayjs(orderDate).format('YYYY-MM-DD') : null;
        const formattedDateTime = valueGio ? dayjs(valueGio).format('HH:mm') : null;
        // const url = 'https://hoangthongtelecom.com/API/SendZalo/v1/Send_Zns.php';
        const url = 'https://zns.hcrm.online/API/Campaigns/insert.php';

        const numberWithoutCommas = removeCommas(valueMoneyCamp);

        const requestData = {
            name: valueNameCamp,
            fromDate: formattedDateFrom,
            toDate: formattedDateTO,
            money: numberWithoutCommas,
            min_order_date: formattedDateOrder,
            send_interval_days: valueDateSend,
            time_send: formattedDateTime,
            //isDelete: '1'

        };
        setOpen(false);
        setOpenMobile(false);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //  'API-Key':'846071896322'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const dataAPI = await response.text();
            const value = dataAPI.error;
            // if (value === 0) {

            //     setValueCode('');
            //     setValueName('');
            //     setValuePhone('');
            //     setValueOrderCode(null);
            //     setValuePrice('');
            //     setValueThongBao("Gửi thành công");
            // } else {
            //     setValueThongBao("Gửi không thành công");
            // }
            // setOpenThongBao(true);
            console.log(dataAPI);

            // setOpenMessage(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const [openOrder, setOpenOrder] = React.useState(false);
    const [openOrderSP, setOpenOrderSP] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openFindCustomer = Boolean(anchorEl);
    const [valueCustomer, setValueCustomer] = useState([]);
    const [valueNV, setValueNV] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [inputValueVAT, setInputValueVAT] = useState('0');

    const handleChangeVAT = (event) => {
        setInputValueVAT(event.target.value); // Cập nhật giá trị input
    };
    const id = openFindCustomer ? 'simple-popper' : undefined;
    const handleClickFindCustomer = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Dark Knight', year: 2008 },
    ];

    const handleInputChange = async (event, value) => {
        setSearchText(value);

        if (value.length >= 0) {
            setLoading(true);
            try {
                const response = await fetch('https://zns.hcrm.online/API/Customer/searchID.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: value }), // Gửi từ khóa tìm kiếm
                });
                const data = await response.json();
                setValueCustomer(data || []); // Giả sử API trả về danh sách trong `products`
            } catch (error) {
                console.error('Lỗi khi tìm kiếm sản phẩm:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setValueCustomer([]);
        }
    };
    const handleInputChangeNV = async (event, value) => {
        setSearchText(value);

        if (value.length >= 0) {
            setLoading(true);
            try {
                const response = await fetch('https://zns.hcrm.online/API/Customer/searchNV.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: value }), // Gửi từ khóa tìm kiếm
                });
                const data = await response.json();
                setValueNV(data || []); // Giả sử API trả về danh sách trong `products`
            } catch (error) {
                console.error('Lỗi khi tìm kiếm sản phẩm:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setValueNV([]);
        }
    };
    return (
        <div>
            {/* <Snackbar
                open={openThongBao}
                autoHideDuration={5000}
                
                onClose={handleCloseThongBao}
                message={valueThongBao}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            /> */}
            <Snackbar open={openThongBao} autoHideDuration={6000} onClose={handleCloseThongBao}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseThongBao}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {valueThongBao}
                </Alert>

            </Snackbar>


            <div className="desktop-layout">
                {
                    openOrder === false &&
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={value}
                                        format="YYYY-MM-DD"
                                        onChange={handleDateChange}
                                    />
                                </LocalizationProvider>
                            </div>
                            <Stack direction="row" spacing={1} style={{ paddingTop: '5px', marginRight: '20px' }}>
                                <Chip label={`Tổng đơn: ${data.length}`} />
                                <Chip label={`Tổng tiền: ${formatCurrency(valueTotal)}`} color="success" />
                            </Stack>
                            <BootstrapButton size="small" onClick={() => setOpenOrder(true)} style={{ marginTop: '0px' }} variant="contained" disableRipple >
                                Tạo báo giá
                            </BootstrapButton>
                            {/* <Button variant="contained" size="small" onClick={() => setOpenChiendich(true)}>Tạo chiến dịch</Button> */}
                        </div>
                        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                            <TableContainer sx={{ maxHeight: "80vh" }}>
                                <Table stickyHeader aria-label="sticky table" size="small">
                                    <TableHead >
                                        <TableRow >
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, backgroundColor: 'grey' }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })} */}
                                        {
                                            data.message === "Không có dữ liệu nào." ? <div></div> : data.map((item, index) => (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={item.so_ct}>
                                                    <TableCell key={item.so_ct} >
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell key={item.so_ct} >
                                                        {item.so_ct}
                                                    </TableCell>
                                                    {/* <TableCell key={item.so_ct} >
                                                {item.ngay_ct}
                                            </TableCell> */}
                                                    <TableCell key={item.so_ct} >
                                                        {item.ma_kh}
                                                    </TableCell>
                                                    <TableCell key={item.so_ct} >
                                                        {item.ten_kh_vat}
                                                    </TableCell>
                                                    <TableCell key={item.SDT_nguoi_mua} >
                                                        {item.SDT_nguoi_mua}
                                                    </TableCell>
                                                    <TableCell key={item.so_ct} align="right" >
                                                        {formatCurrency(item.t_tt)}
                                                    </TableCell>
                                                    <TableCell key={item.so_ct} align="center" >
                                                        {item.status}
                                                    </TableCell>
                                                    <TableCell key={item.so_ct} align="center" >
                                                        {item.count_Send}
                                                    </TableCell>
                                                    <TableCell key={item.so_ct} align="center" >
                                                        <BootstrapButton variant="contained" disableRipple onClick={(e) => handleClickOpen(item)}>
                                                            Zalo
                                                        </BootstrapButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                        </Paper>
                    </div>
                }

                {
                    openOrder === true &&
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>  Tạo đơn hàng</div>
                            <div>
                                <BootstrapButton size="small" onClick={() => setOpenOrder(false)} style={{ marginTop: '0px', marginRight: '8px' }} variant="contained" disableRipple >
                                    Hủy
                                </BootstrapButton>
                                <BootstrapButton size="small" style={{ marginTop: '0px' }} variant="contained" disableRipple >
                                    Tạo đơn hàng
                                </BootstrapButton>
                            </div>
                        </div>
                        <div>
                            <div style={{ padding: '8px', marginTop: '5px', height: '190px', display: 'flex' }}>
                                <div style={{
                                    backgroundColor: 'white',
                                    width: '70%',
                                    marginRight: '8px',
                                    padding: '8px',
                                    borderRadius: '8px'
                                }}>
                                    <div>  Thông tin khách hàng</div>
                                    <div>


                                        <Autocomplete
                                            id="product-search"
                                            size="small"
                                            options={valueCustomer}
                                            getOptionLabel={(option) => option.ten_kh}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Nhập tìm sản phẩm" onChange={(e) => handleInputChange(e, e.target.value)} />
                                            )}
                                            // onChange={handleProductChange} // Sự kiện khi chọn sản phẩm
                                            renderOption={(props, option) => (
                                                <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
                                                    <Table size="small">
                                                        {/* <TableHead>
                                                    <TableRow>
                                                        <TableCell>Tên Sản Phẩm</TableCell>
                                                        <TableCell align="right">Giá</TableCell>
                                                    </TableRow>
                                                </TableHead> */}
                                                        <TableBody>
                                                            <TableRow {...props}>
                                                                <TableCell>{option.ma_kh}</TableCell>
                                                                <TableCell align="right">{option.ten_kh}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        />
                                        <div style={{ marginTop: '5px' }}></div>
                                        <div>Địa chỉ :</div>
                                        <div style={{ marginTop: '5px' }}></div>
                                        <div>Điện thoại :</div>
                                        <div style={{ marginTop: '5px' }}></div>
                                        <div>MST :</div>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: 'white', width: '30%', padding: '8px', borderRadius: '8px' }}>
                                    <div>  Nhân viên bán hàng</div>
                                    <div>


                                        <Autocomplete
                                            id="product-search"
                                            size="small"
                                            options={valueNV}
                                            getOptionLabel={(option) => option.ma_kh}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Nhập tìm sản phẩm" onChange={(e) => handleInputChangeNV(e, e.target.value)} />
                                            )}
                                            // onChange={handleProductChange} // Sự kiện khi chọn sản phẩm
                                            renderOption={(props, option) => (
                                                <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
                                                    <Table size="small">
                                                        {/* <TableHead>
                                                    <TableRow>
                                                        <TableCell>Tên Sản Phẩm</TableCell>
                                                        <TableCell align="right">Giá</TableCell>
                                                    </TableRow>
                                                </TableHead> */}
                                                        <TableBody>
                                                            <TableRow {...props}>
                                                                <TableCell>{option.ma_kh}</TableCell>
                                                                <TableCell align="right">{option.ten_kh}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        />
                                        <div style={{ marginTop: '5px' }}>Ngày đơn hàng</div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box sx={{ width: '100%' }}>
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={(newValue) => setStartDate(newValue)}
                                                    renderInput={(props) => (
                                                        <TextField
                                                            {...props}
                                                            InputProps={{
                                                                sx: {
                                                                    height: '30px',
                                                                    fontSize: '14px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </div>
                            <div style={{ backgroundColor: 'white', padding: '8px', marginTop: '10px', height: '200px' }}>
                                <div>  Thông tin sản phẩm</div>
                                <div>
                                    <Paper
                                        component="form"
                                        sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: '100%' }}
                                    >
                                        {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
                                        <MenuIcon />
                                    </IconButton> */}
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            size="small"
                                            placeholder="Nhập tìm khách hàng"
                                            inputProps={{ 'aria-label': 'search google maps' }}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                                        <DirectionsIcon />
                                    </IconButton> */}
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="mobile-layout">
                {
                    openOrder === false &&
                    <div>
                        <div style={{ display: 'flex' }}>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={value}
                                    format="YYYY-MM-DD"

                                    onChange={handleDateChange}
                                />
                            </LocalizationProvider>
                            <div style={{ flexGrow: 1 }}>

                            </div>
                            <BootstrapButton size="small" onClick={() => setOpenOrder(true)} style={{ marginTop: '0px' }} variant="contained" disableRipple >
                                Tạo báo giá
                            </BootstrapButton>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div style={{ marginTop: '8px', color: 'blue', marginRight: '10px' }}>Danh sách báo giá</div>
                            <Stack direction="row" spacing={1} style={{ paddingTop: '5px', marginRight: '20px' }}>
                                <Chip label={`Tổng đơn: ${data.length}`} />
                                <Chip label={`Tổng tiền: ${formatCurrency(valueTotal)}`} color="success" />
                            </Stack>
                        </div>

                        <hr></hr>
                        {
                            data.message === "Không có dữ liệu nào." ? <div></div> : data.map((item, index) => (
                                <Card sx={{
                                    minWidth: 180, height: '110px',
                                    margin: '5px',
                                    cursor: 'pointer',
                                    ":hover": {
                                        border: "1px solid blue",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                                    }
                                }}>
                                    <CardContent>
                                        <div>
                                            <div style={{ height: '50px' }}>{item.ten_kh_vat}</div>
                                            <div style={{ height: '10px' }}></div>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ flexGrow: 1, color: 'red' }}>{formatCurrency(item.t_tt)}</div>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ marginRight: '5px' }}>{item.status}</div>
                                                    <div style={{ marginRight: '20px', color: 'red' }}>{item.count_Send === 0 ? '' : `(${item.count_Send})`}</div>
                                                </div>

                                                <BootstrapButton size="small" style={{ marginTop: '-8px' }} variant="contained" disableRipple onClick={(e) => handleClickOpenMobile(item)}>
                                                    Zalo
                                                </BootstrapButton>
                                            </div>

                                        </div>
                                    </CardContent>

                                </Card>
                                // <div>
                                //     <div>{item.ten_kh_vat}</div>
                                // </div>
                            ))
                        }
                    </div>
                }
                {
                    openOrder === true &&
                    <div>
                        {
                            openOrderSP === false &&
                            <div >
                                <div style={{ backgroundColor: 'white', padding: '5px' }}>
                                    <div>Sản phẩm</div>
                                    <div>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Brunch this weekend?"
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={{ color: 'text.primary', display: 'inline' }}
                                                            >
                                                                Ali Connors
                                                            </Typography>
                                                            <div style={{display:'flex'}}>
                                                                <div style={{flexGrow:1}}>VAT</div>
                                                                <input
                                                                    type="text"
                                                                    value={inputValueVAT}
                                                                    onChange={handleChangeVAT}
                                                                    placeholder="Nhập văn bản"
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                            
                                            
                                        </List>
                                    </div>
                                    <div>
                                        <BootstrapButton size="small" fullWidth style={{ marginTop: '5px' }} variant="contained" disableRipple onClick={(e) => setOpenOrderSP(true)}>
                                            Chọn sản phẩm
                                        </BootstrapButton>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: 'white', padding: '5px', marginTop: '8px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }}>Khách hàng</div>
                                        <button className="add-button">+</button>
                                    </div>

                                    <div></div>
                                    <div>

                                    </div>
                                </div>
                                <div style={{ backgroundColor: 'white', padding: '5px', marginTop: '8px' }}>
                                    <div style={{ flexGrow: 1 }}>Thanh toán</div>

                                    <div style={{ display: 'flex', marginTop: '5px' }}>
                                        <div style={{ flexGrow: 1 }}>Tổng sản lượng : </div>
                                        <div>0 </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '5px' }}>
                                        <div style={{ flexGrow: 1 }}>Tổng tiền hàng : </div>
                                        <div>0 </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '5px' }}>
                                        <div style={{ flexGrow: 1, color: 'blue' }}>Phí giao hàng : </div>
                                        <TextField style={{ marginTop: '-8px' }} id="outlined-basic" size="small" variant="standard" />
                                    </div>
                                    <div style={{ display: 'flex', marginTop: '5px' }}>
                                        <div style={{ flexGrow: 1 }}>Thành tiền : </div>
                                        <div>0 </div>
                                    </div>
                                </div>
                                <div className="fixed-bottom-button">
                                    <button className="bottom-button">Gửi Zalo</button>
                                    <button className="bottom-button" >Tạo đơn</button>
                                </div>
                            </div>
                        }
                        {
                            openOrderSP === true &&
                            <div style={{ backgroundColor: 'white', padding: '5px', height: '100vh' }}>
                                <div >
                                    <div>
                                        <h1>Nội dung trang</h1>
                                        <p>Thêm nội dung ở đây...</p>
                                        <div className="fixed-bottom-button">
                                            <button className="bottom-button">Hoàn tất</button>
                                            <button className="bottom-button" onClick={() => setOpenOrderSP(false)}>Hủy bỏ</button>
                                        </div>
                                        {/*  */}
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

            </div>





            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Gửi tin nhắn Zalo
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Box>
                            <div>Khách hàng</div>
                            <TextField id="outlined-basic" value={valueName} onChange={(e) => {
                                setValueName(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Box sx={{ height: '10px' }}></Box>
                        <Box>
                            <div>Số điện thoại</div>
                            <TextField id="outlined-basic" value={valuePhone} onChange={(e) => {
                                setValuePhone(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Mã đơn</div>
                                    <TextField id="outlined-basic" value={valueOrderCode} onChange={(e) => {
                                        setValueOrderCode(e.target.value)
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box >
                                    <div>Trạng thái đơn hàng</div>
                                    <TextField id="outlined-basic" value={valueStatus} onChange={(e) => {
                                        setValueStatus(e.target.value);
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={valueStatus}
                                        fullWidth
                                        sx={{ height: '40px' }}
                                        onChange={handleChangeStatus}
                                    >
                                        <MenuItem value={"Đang giao"}>Đang giao</MenuItem>
                                        <MenuItem value={"Giao hàng thành công"}>Giao thành công</MenuItem>
                                        <MenuItem value={"Đã thanh toán"}>Đã thanh toán</MenuItem>
                                    </Select> */}
                                </Box>
                            </Grid>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Giá tiền</div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Ngày đặt hàng</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                value={value}
                                                onChange={(newValue) => setValue(newValue)}
                                                renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>

                                </Box>
                            </Grid>

                        </Grid>

                        <Box sx={{ height: '10px' }}></Box>





                        {/* <Box>
                        <div>Ngày đặt hàng</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '100%' }}>
                                <DatePicker
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                />
                            </Box>
                        </LocalizationProvider>

                    </Box> */}

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => sendPhone()}>
                        Gửi tin nhắn
                    </Button>
                </DialogActions>
            </BootstrapDialog>


            <BootstrapDialog
                onClose={handleCloseMobile}
                fullScreen
                aria-labelledby="customized-dialog-title"
                open={openMobile}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Gửi tin nhắn Zalo
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseMobile}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Box>
                            <div>Khách hàng</div>
                            <TextField multiline id="outlined-multiline-flexible" maxRows={4} value={valueName}
                                onChange={(e) => {
                                    setValueName(e.target.value)
                                }}
                                fullWidth />
                        </Box>
                        <Box sx={{ height: '10px' }}></Box>
                        <Box>
                            <div>Số điện thoại</div>
                            <TextField id="outlined-basic" value={valuePhone} onChange={(e) => {
                                setValuePhone(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Grid container spacing={2}>

                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Mã đơn</div>
                                    <TextField id="outlined-basic" value={valueOrderCode} onChange={(e) => {
                                        setValueOrderCode(e.target.value)
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box >
                                    <div>Trạng thái đơn hàng</div>
                                    <TextField id="outlined-basic" value={valueStatus} onChange={(e) => {
                                        setValueStatus(e.target.value);
                                    }} fullWidth variant="outlined" className={classes.textField} />

                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={valueStatus}
                                        fullWidth
                                        sx={{ height: '40px' }}
                                        onChange={handleChangeStatus}
                                    >
                                        <MenuItem value={"Đặt hàng thành công"}>Đặt hàng thành công</MenuItem>
                                        <MenuItem value={"Đang giao"}>Đang giao</MenuItem>
                                        <MenuItem value={"Giao hàng thành công"}>Giao thành công</MenuItem>
                                        <MenuItem value={"Đã thanh toán"}>Đã thanh toán</MenuItem>
                                    </Select> */}
                                </Box>
                            </Grid>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Giá tiền</div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Ngày đặt hàng</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                value={value}
                                                onChange={(newValue) => setValue(newValue)}
                                                renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>

                                </Box>
                            </Grid>

                        </Grid>

                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{ color: 'red' }}>{valueCheck}</div>
                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            textAlign: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button variant="contained" autoFocus onClick={() => sendPhone()}>
                                Gửi tin nhắn
                            </Button>
                        </div>

                        {/* <Box>
                        <div>Ngày đặt hàng</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '100%' }}>
                                <DatePicker
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                />
                            </Box>
                        </LocalizationProvider>

                    </Box> */}

                    </Box>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={() => sendPhone()}>
                        Gửi tin nhắn
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>



            <BootstrapDialog
                onClose={handleCloseChienDich}

                aria-labelledby="customized-dialog-title"
                open={openChiendich}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Tạo chiến dịch gửi zalo
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseChienDich}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{
                        width: {
                            xs: '300px', // Mobile
                            sm: '600px', // Desktop
                        }
                    }}>


                        <Box>
                            <div>Tên chiến dịch</div>
                            <TextField id="outlined-basic" onChange={(e) => {
                                setValueNameCamp(e.target.value)
                            }} value={valueNameCamp} sx={{
                                width: {
                                    xs: '100%',
                                    sm: '550px'
                                }
                            }} variant="outlined" className={classes.textField} />
                            {/* <TextField multiline id="outlined-multiline-flexible" maxRows={1} value={valueName}
                                onChange={(e) => {
                                    setValueName(e.target.value)
                                }}
                                fullWidth /> */}
                        </Box>
                        <Box sx={{ height: '10px' }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <div>Chiến dịch từ ngày</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{ width: '100%' }}>
                                        <DatePicker
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputProps={{
                                                        sx: {
                                                            height: '30px',
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div>Đến ngày</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{ width: '100%' }}>
                                        <DatePicker
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputProps={{
                                                        sx: {
                                                            height: '35px',
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </LocalizationProvider>


                            </Grid>
                        </Grid>
                        <Box sx={{ height: '10px' }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <Box>
                                    <div>Số tiền nhỏ nhất</div>
                                    <TextField fullWidth style={{ width: '275px' }} id="outlined-basic" onChange={handlePriceChangeCamp} value={valueMoneyCamp} variant="outlined" className={classes1.textField} />
                                </Box>

                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <div>Ngày đơn hàng nhỏ nhất</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{ width: '100%' }}>
                                        <DatePicker
                                            value={endDate}
                                            onChange={(newValue) => setOrderDate(newValue)}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputProps={{
                                                        sx: {
                                                            height: '35px',
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </LocalizationProvider>

                            </Grid>
                        </Grid>
                        <Box sx={{ height: '10px' }}></Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ width: '100%' }}>
                                    <div>Khoảng cách ngày gửi</div>
                                    <TextField
                                        sx={{
                                            width: {
                                                xs: '300px', // Mobile
                                                sm: '275px', // Desktop
                                            },
                                        }}
                                        id="outlined-basic"
                                        onChange={(e) => setValueDateSend(e.target.value)}
                                        value={valueDateSend}
                                        size="small"
                                        variant="outlined"
                                        className={classes1.textField}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div>Thời gian gửi tin</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker ampm={false}
                                        value={valueGio}
                                        onChange={(newValue) => setValueGio(newValue)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>



                        {/* <Grid container spacing={2}>
                            <Grid size={6}>
                                <Box>
                                    <div></div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                            </Grid>
                            <Grid size={6}>
                                <Box>
                                    <div>Thời gian gửi tin</div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                            </Grid>
                        </Grid> */}

                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{ color: 'red' }}>{valueCheck}</div>
                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            textAlign: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button variant="contained" autoFocus onClick={() => sendCamp()}>
                                Lưu chiến dịch
                            </Button>
                        </div>

                        {/* <Box>
                        <div>Ngày đặt hàng</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '100%' }}>
                                <DatePicker
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                />
                            </Box>
                        </LocalizationProvider>

                    </Box> */}

                    </Box>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={() => sendPhone()}>
                        Gửi tin nhắn
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>


        </div>
    );
}
export default BaoGiaPage;